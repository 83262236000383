import React, {Component} from 'react';
import plc from '../img/current/IntemsPLC-asm_1.png'
import plc_1 from '../img/current/IntemsPLC-asm_6-PhotoRoom 4.png'
import plc_2 from '../img/current/IntemsPLC-asm_6-PhotoRoom 3.png'
import plc_3 from '../img/current/IntemsPLC-asm_3-PhotoRoom 2.png'
import plc_4 from '../img/current/IntemsPLC-asm_9 1.png'
import plc_5 from '../img/current/IntemsPLC-asm_8 1.png'
import plc_6 from '../img/current/IntemsPLC-asm_7 1.png'
import plc_beremiz from '../img/current/Group 387.png'
import "../components/style/Page.css"
import {t} from "i18next";

class IntemsPlc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={plc} alt='PLC'/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("plc.header")}</div>
                                <div className="mini-header light">{t("plc.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page_center header_page">{t("plc.title_1.name")}
                            <button onClick={() => this.setState({openImage: !this.state.openImage})}
                                    className="page_btn">
                                <i className="fa fa-arrows-alt"></i>
                                {this.state.openImage ? t("plc.title_1.btn_on") : t("plc.title_1.btn_off")}
                            </button>
                        </div>
                        <div
                            className={!this.state.openImage ? "plc_transform_container" : "plc_transform_container plc_transform_container_"}>
                            <div className="plc_transform">
                                <img
                                    className={!this.state.openImage ? "plc1 plc_transform_img" : "plc1 plc1_ plc_transform_img plc_transform_img_"}
                                    src={plc_1} alt='PLC1'/>
                                <img
                                    className={!this.state.openImage ? "plc4 plc_transform_img" : "plc4 plc4_ plc_transform_img plc_transform_img_"}
                                    src={plc_4} alt='PLC4'/>
                                <img
                                    className={!this.state.openImage ? "plc5 plc_transform_img" : "plc5 plc5_ plc_transform_img plc_transform_img_"}
                                    src={plc_5} alt='PLC5'/>
                                <img
                                    className={!this.state.openImage ? "plc6 plc_transform_img" : "plc6 plc6_ plc_transform_img plc_transform_img_"}
                                    src={plc_6} alt='PLC6'/>
                                <img
                                    className={!this.state.openImage ? "plc3 plc_transform_img" : "plc3 plc3_ plc_transform_img plc_transform_img_"}
                                    src={plc_3} alt='PLC3'/>
                            </div>
                            {/*<div>
                                <i className="arrow fa fa-arrow-left"></i>
                                <i className="arrow fa fa-arrow-right"></i>
                            </div>*/}
                            <img
                                className={!this.state.openImage ? "plc_beremiz " : "plc_beremiz plc_beremiz_ "}
                                src={plc_beremiz} alt='beremiz'/>
                        </div>
                        <div className="main_text">
                            <div className="block_container">
                                <div>
                                    <div className="header_block">{t("plc.title_1.1.header")}</div>
                                    <div className="text_block">
                                        <ul>
                                            <li>{t("plc.title_1.1.body.1")}</li>
                                            <li>{t("plc.title_1.1.body.2")}</li>
                                            <li>{t("plc.title_1.1.body.3")}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div className="header_block">{t("plc.title_1.2.header")}</div>
                                    <div className="text_block">
                                        <ul>
                                            <li>{t("plc.title_1.2.body.1")}</li>
                                            <li>{t("plc.title_1.2.body.2")}</li>
                                            <li>{t("plc.title_1.2.body.3")}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div className="header_block">{t("plc.title_1.3.header")}</div>
                                    <div className="text_block">
                                        <ul>
                                            <li>{t("plc.title_1.3.body.1")}</li>
                                            <li>{t("plc.title_1.3.body.2")}</li>
                                            <li>{t("plc.title_1.3.body.3")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="header_page_center header_page">{t("plc.title_2.name")}</div>
                        <div className="main_text">{t("plc.title_2.text")}</div>
                        <div className="block_container">
                            <div>
                                <a href="https://intemslab.ru/downloads/intems-plc/intems-plc-windows-0.2.0.zip" download
                                   className="modal-btn download_btn"><i
                                    className="fa fa-download" aria-hidden="true"></i>Windows</a>
                                <p className="block_container reference">(*.zip)</p>
                            </div>
                            <div>
                                <a href="https://intemslab.ru/downloads/intems-plc/intems-plc-linux-0.2.0.zip" download
                                   className="modal-btn download_btn"><i
                                    className="fa fa-download" aria-hidden="true"></i>Linux</a>
                                <p className="block_container reference">(*.zip)</p>
                            </div>
                        </div>
                        <div className="main_text">{t("plc.title_2.ul.text")}
                            <li>{t("plc.title_2.ul.1")}</li>
                            <li>{t("plc.title_2.ul.2")}</li>
                            <li>{t("plc.title_2.ul.3")}</li>
                        </div>

                        <div className="header_page_center header_page">{t("plc.title_doc.name")}</div>
                        <div className="main_text">{t("plc.title_doc.text")}</div>
                        <div className="block_container">
                            <div>
                                <a href="https://intemslab.ru/downloads/intems-plc/Документация ILC-001.pdf"
                                      className="modal-btn download_btn"><i
                                    className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.general")}</a>
                            </div>
                            <div>
                                <a href="https://intemslab.ru/downloads/intems-plc/Описание UDP протокола ILC-001.pdf"
                                      className="modal-btn download_btn"><i
                                    className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.udp-proto")}</a>
                            </div>
                        </div>

                        <div className="header_page_center header_page">{t("plc.title_3.name")}</div>
                        <p className="header_page_center header_page_mini">{t("plc.title_3.programming.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.programming.row_1.1")}</td>
                                    <td>{t("plc.title_3.programming.row_1.2.text")}
                                        <ul>
                                            <li>{t("plc.title_3.programming.row_1.2.1")}</li>
                                            <li>{t("plc.title_3.programming.row_1.2.2")}</li>
                                            <li>{t("plc.title_3.programming.row_1.2.3")}</li>
                                            <li>{t("plc.title_3.programming.row_1.2.4")}</li>
                                            <li>{t("plc.title_3.programming.row_1.2.5")}</li>
                                        </ul>
                                    </td>
                                    <td>{t("plc.title_3.programming.row_1.3")}</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title"> {t("plc.title_3.programming.row_2.1")}</td>
                                    <td>Beremiz <a href="https://beremiz.org/">(https://beremiz.org/about)</a></td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.programming.row_2.3.1")}</li>
                                            <li>{t("plc.title_3.programming.row_2.3.2")}</li>
                                            <li>{t("plc.title_3.programming.row_2.3.3")}</li>
                                            <li>{t("plc.title_3.programming.row_2.3.4")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.programming.row_3.1")}</td>
                                    <td>{t("plc.title_3.programming.row_3.2")}</td>
                                    <td>
                                        {t("plc.title_3.programming.row_3.3.text")}
                                        <ul>
                                            <li>{t("plc.title_3.programming.row_3.3.1")}</li>
                                            <li>{t("plc.title_3.programming.row_3.3.2")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("plc.title_3.programming.row_1.1")}</p>
                                {t("plc.title_3.programming.row_1.2.text")}
                                <ul>
                                    <li>{t("plc.title_3.programming.row_1.2.1")}</li>
                                    <li>{t("plc.title_3.programming.row_1.2.2")}</li>
                                    <li>{t("plc.title_3.programming.row_1.2.3")}</li>
                                    <li>{t("plc.title_3.programming.row_1.2.4")}</li>
                                    <li>{t("plc.title_3.programming.row_1.2.5")}</li>
                                </ul>
                                <p>{t("plc.title_3.programming.row_1.3")}</p>
                                <p><p className="table_title">{t("plc.title_3.programming.row_2.1")}</p>
                                    Beremiz <a href="https://beremiz.org/">(https://beremiz.org/about)</a></p>
                                <ul>
                                    <li>{t("plc.title_3.programming.row_2.3.1")}</li>
                                    <li>{t("plc.title_3.programming.row_2.3.2")}</li>
                                    <li>{t("plc.title_3.programming.row_2.3.3")}</li>
                                    <li>{t("plc.title_3.programming.row_2.3.4")}</li>
                                </ul>
                                <p><p className="table_title">{t("plc.title_3.programming.row_3.1")}</p>
                                    <p>{t("plc.title_3.programming.row_3.2")}</p>
                                    {t("plc.title_3.programming.row_3.3.text")}
                                </p>
                                <ul>
                                    <li>{t("plc.title_3.programming.row_3.3.1")}</li>
                                    <li>{t("plc.title_3.programming.row_3.3.2")}</li>
                                </ul>
                            </div>
                        </div>
                        <p className="header_page_center header_page_mini">{t("plc.title_3.power.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.power.row_1.1")}</td>
                                    <td>{t("plc.title_3.power.row_1.2")}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.power.row_2.1")}</td>
                                    <td>{t("plc.title_3.power.row_2.2")}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.power.row_3.1")}</td>
                                    <td>{t("plc.title_3.power.row_3.2")}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <div className="main_text text">
                                    <p><p className="table_title">{t("plc.title_3.power.row_1.1")}:</p> {t("plc.title_3.power.row_1.2")}</p>
                                    <p><p className="table_title">{t("plc.title_3.power.row_2.1")}:</p> {t("plc.title_3.power.row_2.2")}</p>
                                    <p><p className="table_title">{t("plc.title_3.power.row_3.1")}:</p> {t("plc.title_3.power.row_3.2")}</p>
                                </div>
                            </div>
                        </div>
                        <p className="header_page_center header_page_mini">{t("plc.title_3.input.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.input.row_1.1")}</td>
                                    <td>{t("plc.title_3.input.row_1.2")}</td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.input.row_1.3.1")}</li>
                                            <li>{t("plc.title_3.input.row_1.3.2")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.input.row_2.1")}</td>
                                    <td>{t("plc.title_3.input.row_2.2")}</td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.input.row_2.3.1")}</li>
                                            <li>{t("plc.title_3.input.row_2.3.2")}</li>
                                            <li>{t("plc.title_3.input.row_2.3.3")}</li>
                                            <li>{t("plc.title_3.input.row_2.3.4.text")}
                                                <ul>
                                                    <li>{t("plc.title_3.input.row_2.3.4.1")}</li>
                                                    <li>{t("plc.title_3.input.row_2.3.4.2")}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.input.row_3.1")}</td>
                                    <td>{t("plc.title_3.input.row_3.2")}</td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.input.row_3.3.1")}</li>
                                            <li>{t("plc.title_3.input.row_3.3.2")}</li>
                                            <li>{t("plc.title_3.input.row_3.3.3")}</li>
                                            <li>{t("plc.title_3.input.row_3.3.4")}</li>
                                            <li>{t("plc.title_3.input.row_3.3.5")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <div className="main_text text">
                                    <p><p className="table_title">{t("plc.title_3.input.row_1.1")}:</p>
                                        {t("plc.title_3.input.row_1.2")}
                                    </p>
                                    <ul>
                                        <li>{t("plc.title_3.input.row_1.3.1")}</li>
                                        <li>{t("plc.title_3.input.row_1.3.2")}</li>
                                    </ul>
                                    <p><p className="table_title">{t("plc.title_3.input.row_2.1")}:</p>
                                        {t("plc.title_3.input.row_2.2")}
                                    </p>
                                    <ul>
                                        <li>{t("plc.title_3.input.row_2.3.1")}</li>
                                        <li>{t("plc.title_3.input.row_2.3.2")}</li>
                                        <li>{t("plc.title_3.input.row_2.3.3")}</li>
                                        <li>{t("plc.title_3.input.row_2.3.4.text")}
                                            <ul>
                                                <li>{t("plc.title_3.input.row_2.3.4.1")}</li>
                                                <li>{t("plc.title_3.input.row_2.3.4.2")}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p><p className="table_title">{t("plc.title_3.input.row_3.1")}:</p>
                                        {t("plc.title_3.input.row_3.2")}</p>
                                    <ul>
                                        <li>{t("plc.title_3.input.row_3.3.1")}</li>
                                        <li>{t("plc.title_3.input.row_3.3.2")}</li>
                                        <li>{t("plc.title_3.input.row_3.3.3")}</li>
                                        <li>{t("plc.title_3.input.row_3.3.4")}</li>
                                        <li>{t("plc.title_3.input.row_3.3.5")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className="header_page_center header_page_mini ">{t("plc.title_3.interfaces.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.interfaces.row_1.1")}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.interfaces.row_2.1")}</td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.interfaces.row_2.2.1")}</li>
                                            <li>{t("plc.title_3.interfaces.row_2.2.2")}</li>
                                            <li>{t("plc.title_3.interfaces.row_2.2.3")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table_line">
                                        <hr className="border"></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table_title">{t("plc.title_3.interfaces.row_3.1")}</td>
                                    <td>
                                        <ul>
                                            <li>{t("plc.title_3.interfaces.row_3.2.1")}</li>
                                            <li>{t("plc.title_3.interfaces.row_3.2.2")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <div className="main_text text">
                                    <p className="table_title">{t("plc.title_3.interfaces.row_1.1")}</p>
                                    <p className="table_title">{t("plc.title_3.interfaces.row_2.1")}</p>
                                    <ul>
                                        <li>{t("plc.title_3.interfaces.row_2.2.1")}</li>
                                        <li>{t("plc.title_3.interfaces.row_2.2.2")}</li>
                                        <li>{t("plc.title_3.interfaces.row_2.2.3")}</li>
                                    </ul>
                                    <p className="table_title">{t("plc.title_3.interfaces.row_3.1")}</p>
                                    <ul>
                                        <li>{t("plc.title_3.interfaces.row_3.2.1")}</li>
                                        <li>{t("plc.title_3.interfaces.row_3.2.2")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntemsPlc;